/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.search-box {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  white-space: nowrap;
}

.search-box,
.search-box-input {
  width: 100%;
  max-width: 300px;
}

.search-box-input {
  /* for magnifier icon */
  padding-left: 24px !important;
  /* for clear button */
  padding-right: 24px !important;
  font-size: 13px;
}

.search-box-input::-webkit-search-decoration,
.search-box-input::-webkit-search-cancel-button,
.search-box-input::-webkit-search-results-button,
.search-box-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
  display: none;
}

.search-box-input::-ms-clear,
.search-box-input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.search-box-note {
  position: absolute;
  top: 1px;
  left: 40px;
  right: 24px;
  line-height: 24px;
  color: #656565;
  font-size: 12px;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  pointer-events: none;
}

.search-box-input:focus ~ .search-box-magnifier {
  color: #236a97;
}

.search-box-magnifier {
  position: absolute;
  top: 4px;
  left: 4px;
  color: #525252;
  transition: color 0.3s ease;
}

.search-box > .spinner {
  position: absolute;
  top: 4px;
  left: 5px;
}

.search-box-clear {
  position: absolute;
  top: 4px;
  right: 4px;
}

.search-box-clear.button:focus {
  box-shadow: 0 0 0 1px white, 0 0 0 4px rgba(35, 106, 151, 0.5);
}

.search-box-input-note {
  position: absolute;
  top: 100%;
  left: 0;
  line-height: 1;
  color: #656565;
  font-size: 12px;
  white-space: nowrap;
}
