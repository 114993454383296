/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.project-baseline {
  padding: 32px;
}

.project-baseline-setting {
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  padding-top: 2px;
}

.branch-baseline-selector > hr {
  margin: 0 -32px 32px;
}

.branch-baseline-setting-modal {
  min-height: 450px;
  display: flex;
  flex-direction: column;
}

.branch-analysis-list-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 200px;
}

.branch-analysis-list {
  overflow-y: auto;
  padding-left: 12px;
  padding-right: 15px;
  min-height: 50px;
}

.branch-analysis-list > ul {
  padding-top: 18px;
}

.branch-analysis-date {
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: bold;
}

.branch-analysis-day {
  margin-top: 8px;
  margin-bottom: 24px;
}

.branch-analysis {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  cursor: not-allowed;
}

.branch-analysis + .branch-analysis {
  border-top: none;
}

.branch-analysis:hover {
  background-color: #ebebeb;
}

.branch-analysis > .project-activity-events {
  flex: 1 0 50%;
}

.branch-analysis-time {
  width: 150px;
}

.branch-analysis-version-badge {
  margin-left: -12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
}

.branch-analysis-version-badge.sticky + .branch-analysis-days-list {
  padding-top: 36px;
}

.branch-analysis-version-badge.sticky,
.branch-analysis-version-badge.first {
  position: absolute;
  top: 1px;
  left: 13px;
  right: 16px;
  padding-top: 24px;
  z-index: 1;
}

.branch-analysis-version-badge .badge {
  max-width: 385px;
  border-radius: 0 2px 2px 0;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.branch-setting-warning {
  background-color: #fcf8e3 !important;
}

.project-activity-event-icon.VERSION {
  color: #4b9fd5;
}

.project-activity-event-icon.QUALITY_GATE {
  color: #9139d4;
}

.project-activity-event-icon.QUALITY_PROFILE {
  color: #cccccc;
}

.project-activity-event-icon.DEFINITION_CHANGE {
  color: #33a759;
}

.project-activity-event-icon.OTHER {
  color: #442d1b;
}
